import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import DrinksList from './DrinksList';

function RecommendedDrinks() {
  return (
    <StaticQuery
      query={graphql`
        {
          allMongodbCocktailmeisterDrinks(
            limit: 8
            filter: { tags: { eq: "popular" } }
            sort: { fields: image }
          ) {
            edges {
              node {
                id
                name
                slug
                ingredients {
                  _0
                }
                category
                alcoholic
                glass
                skill
                image
              }
            }
          }
        }
      `}
      render={(data) => {
        const drinks = get(data, 'allMongodbCocktailmeisterDrinks.edges');
        return (
          <div class="recommended-drinks">
            <h3>Similar Drinks</h3>
            <p>
              Hand picked top 8 similar drinks recipes that are very popular and
              trending around the world. Very easy to mix at home, instructions
              are step by step explained with all the right amount of
              ingredients.
            </p>
            <DrinksList drinks={drinks}></DrinksList>
          </div>
        );
      }}
    ></StaticQuery>
  );
}

export default RecommendedDrinks;
