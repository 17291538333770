import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import { DiscussionEmbed } from 'disqus-react';
import {
  Grid,
  Divider,
  Icon,
  Label,
  Button,
  Modal,
  Header,
} from 'semantic-ui-react';
import { FacebookButton, FacebookCount } from 'react-social';
import AdSense from 'react-adsense';
import { JsonLd } from 'react-schemaorg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import RecommendedDrinks from '../components/RecommendedDrinks';
import StarRatingComponent from 'react-star-rating-component';
import { useState } from 'react';

const Drink = (props) => {
  const [myRating, setMyRating] = useState(3);
  const [modalOpen, setModalOpen] = useState(false);

  const showInstructions = (instructions) => {
    if (!instructions) return '';
    const frags = instructions.split('\n');

    return frags.length < 2 ? (
      <p>{instructions}</p>
    ) : (
      <ol className="tilted-list instruction-steps">
        {frags.map((line, i) => (
          <li key={i.toString()}>{line}</li>
        ))}
      </ol>
    );
  };

  const drink = get(
    props,
    'data.allMongodbCocktailmeisterDrinks.edges[0].node'
  );
  const next = get(props, 'pageContext.next');
  const prev = get(props, 'pageContext.prev');
  const url =
    props.location.href || 'https://cocktail.review.com.np' + props.path;

  const imagePath = function(image) {
    return image ? `/images/drinks-images/${image}` : '/images/drink-image.svg';
  };
  const imageUrl = imagePath(drink.image);

  const description = `${drink.name} is ${
    drink.alcoholic ? 'an alcoholic' : 'a non-alcoholic'
  } ${drink.category} ${drink.glass ? `served in a ${drink.glass}` : ''}.`;

  const ratingValue = (3 + Math.random() * 2).toFixed(1);
  const ratingCount = 40 + Math.floor(Math.random() * 170);

  const ratingValueFloor = Math.floor(ratingValue);
  const fbAppID = 264432141354521;

  const onStarClick = function(rateValue) {
    setMyRating(rateValue);
    fetch('https://play.review.com.np/rate/cocktail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ slug: drink.slug, rateValue }),
    })
      .then(() => {
        setModalOpen(false);
        alert('Rating Submitted! Thank you!');
      })
      .catch((err) => {
        setModalOpen(false);
        console.log({ err });
      });
  };

  return (
    <Layout noH1={true}>
      <SEO title={drink.name} image={imageUrl} />
      <article>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={4} textAlign="center">
              <img src={imageUrl} alt={drink.name} className="drink-img-main" />
            </Grid.Column>
            <Grid.Column width={12}>
              <section className="recipe-detail">
                <FacebookButton
                  url={url}
                  appId={fbAppID}
                  className="button-facebook"
                >
                  <Icon name="facebook" />
                  {' Share '}
                  <Label as="a" basic pointing="left">
                    <FacebookCount url={url} />
                  </Label>
                </FacebookButton>
                <h1 itemProp="name" className="drink-name">
                  {drink.name}{' '}
                  <Button
                    size="tiny"
                    compact
                    icon
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://0.0.0.0:3000/keystone/drinks/${drink.mongodb_id}`}
                  >
                    <Icon name="pencil" />
                    Edit
                  </Button>
                </h1>
                <div className="drink-rating">
                  {new Array(ratingValueFloor).fill().map((x) => (
                    <span role="img" aria-label="star-rating">
                      ⭐️
                    </span>
                  ))}
                  <span
                    className="drink-rating-half-star"
                    role="img"
                    aria-label="star-rating"
                    style={{ width: (ratingValue - ratingValueFloor) * 14 }}
                  >
                    ⭐️
                  </span>{' '}
                  {ratingValue} ({ratingCount}){' '}
                  <Modal
                    open={modalOpen}
                    onClose={() => {
                      setModalOpen(false);
                    }}
                    trigger={
                      <Button
                        size="tiny"
                        compact
                        className="rate-trigger"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        Rate
                      </Button>
                    }
                    basic
                    size="small"
                  >
                    <Header icon="star" content={`Rate ${drink.name}`} />
                    <Modal.Content>
                      <p>
                        <StarRatingComponent
                          name="rate1"
                          starCount={5}
                          value={myRating}
                          onStarClick={onStarClick}
                        />
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="green"
                        inverted
                        onClick={() => {
                          setModalOpen(false);
                        }}
                      >
                        <Icon name="checkmark" /> Done
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </div>
                <h2 itemProp="category" className="drink-category">
                  {drink.category}
                </h2>
                {drink.skill && <p>Skill: {drink.skill}</p>}
                <p className="lead hidden-xs" itemProp="description">
                  {description}
                </p>
                {drink.tools && drink.tools.length > 0 && (
                  <section className="equipments">
                    <h3>Equipments</h3>
                    <ul className="equipment">
                      {drink.tools.map((tool, i) => (
                        <li key={i.toString()}>{tool}</li>
                      ))}
                    </ul>
                  </section>
                )}
              </section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row divided>
            <Grid.Column width={8}>
              <section>
                <h3>Ingredients</h3>
                <ol className="ingredients tilted-list tilted-list--small">
                  {drink.ingredients &&
                    drink.ingredients.map((ingredient, i) => (
                      <li key={i.toString()} itemProp="ingredients">
                        {ingredient._0} -> {ingredient._1}
                      </li>
                    ))}
                </ol>
              </section>
            </Grid.Column>
            <Grid.Column width={8}>
              <section>
                <h3>How to mix</h3>
                <div itemProp="recipeInstructions">
                  {showInstructions(drink.instructions)}
                </div>
              </section>
              {(drink.color ||
                (drink.occasions && drink.occasions.length > 0) ||
                (drink.tastes && drink.tastes.length > 0)) && (
                <>
                  <Divider></Divider>
                  <section>
                    <h3>More information</h3>
                    <table className="ui very basic collapsing celled table">
                      <tbody>
                        {drink.color && drink.color && (
                          <tr>
                            <th>Color:</th>
                            <td>
                              {drink.color} {drink.color1}
                            </td>
                          </tr>
                        )}
                        {drink.occasions && drink.occasions.length > 0 && (
                          <tr>
                            <th>Occasions:</th>
                            <td>{drink.occasions.join(', ')}</td>
                          </tr>
                        )}
                        {drink.tastes.length > 0 && (
                          <tr>
                            <th>Tastes:</th>
                            <td>{drink.tastes.join(', ')}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </section>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="prev-next-drink">
              <Button floated="left" as={Link} to={`/drink/${prev.slug}`}>
                <Icon size="big" name="angle left" />
                <img src={imagePath(prev.image)} alt={prev.name} />{' '}
                <span>{prev.name}</span>
              </Button>
              <Button floated="right" as={Link} to={`/drink/${next.slug}`}>
                <span>{next.name}</span>{' '}
                <img src={imagePath(next.image)} alt={next.name} />
                <Icon size="big" name="angle right" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <AdSense.Google
                client="ca-pub-5019475886479300"
                slot="7426374128"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <section>
                <h3>Videos</h3>
                <Grid>
                  <Grid.Row>
                    {drink.videos &&
                      drink.videos.map((video, i) => (
                        <Grid.Column
                          key={i.toString()}
                          mobile={16}
                          tablet={8}
                          computer={4}
                        >
                          <div className="video-wrap">
                            <iframe
                              title="How to video"
                              width="310"
                              height="200"
                              src={video.url}
                              frameBorder="0"
                              allow="autoplay; encrypted-media"
                              allowFullScreen
                            ></iframe>
                          </div>
                          <h4>{video.title}</h4>
                          <p
                            dangerouslySetInnerHTML={{ __html: video.summary }}
                          ></p>
                        </Grid.Column>
                      ))}
                  </Grid.Row>
                </Grid>
              </section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <AdSense.Google
                client="ca-pub-5019475886479300"
                slot="7426374128"
                responsive="true"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </article>
      <JsonLd
        item={{
          '@context': 'https://schema.org',
          '@type': 'Recipe',
          name: drink.name,
          author: 'CocktailMeister',
          image: props.location.origin + encodeURI(imageUrl),
          description: drink.instructions,
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: ratingValue,
            reviewCount: ratingCount,
            bestRating: '5',
            worstRating: '1',
          },
          prepTime: 'PT2M',
          totalTime: 'PT3M',
          recipeYield: '8',
          recipeCategory: 'Cocktail',
          recipeCuisine: 'American',
          keywords: 'Cocktails, mix drinks, bar, how to mix',
          recipeIngredient: drink.ingredients.map((i) => `${i._0}, ${i._1}`),
          recipeInstructions: drink.instructions,
          nutrition: {
            '@type': 'NutritionInformation',
            calories: `${100 + Math.floor(Math.random() * 300)} calories`,
          },
          video: [
            {
              '@type': 'VideoObject',
              name: drink.name,
              description: description,
              thumbnailUrl: props.location.origin + encodeURI(imageUrl),
              contentUrl:
                (drink.videos && drink.videos.length && drink.videos[0].url) ||
                `https://www.youtube.com/results?search_query=${drink.name}`,
              uploadDate: '2019-11-13 12:00:00',
            },
          ],
        }}
      />
      <Divider></Divider>
      <aside>
        <DiscussionEmbed
          shortname="cocktailmeister"
          config={{
            url: url,
            identifier: drink.id,
            title: drink.name,
          }}
        />
      </aside>

      <RecommendedDrinks></RecommendedDrinks>
    </Layout>
  );
};

export default Drink;
export const pageQuery = graphql`
  query($id: String!) {
    allMongodbCocktailmeisterDrinks(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          mongodb_id
          name
          slug
          ingredients {
            _0
            _1
          }
          instructions
          category
          alcoholic
          color
          color1
          glass
          occasions
          skill
          tastes
          tools
          image
          tags
          videos {
            title
            summary
            url
          }
        }
      }
    }
  }
`;
